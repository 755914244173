import { useState } from "react";

import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';

import audioviz from '../media/newaudio.png';
import av1 from '../media/audio1.png';
import av2 from '../media/audio2.png';
import p1 from '../media/prism1.png';
import p2 from '../media/prism2.png';
import data1 from '../media/steam.png';
import data2 from '../media/twitch.png';
import onenight from '../media/newnight.png';
import prism from '../media/newprism.png';

import './Projects.css';
import ParticlesBackground from "../components/ParticlesBackground";

const Projects = () => {

  let initialProjects = [
    {
      id: 'audioviz',
      open: true
    },
    {
      id: 'onenight',
      open: true
    },
    {
      id: 'prism',
      url: 'https://kiernanbrown.com/prism/',
      link: 'Play Prism Here',
      open: false
    },
    {
      id: 'data',
      open: false
    }
  ];

  const [projects, setProjects] = useState(
    initialProjects
  );

  const toggleProject = (id) => {
    const updatedProjects = projects.map(project => {
      if (project.id === id) {
        return {
          ...project,
          open: !project.open
        }
      }
      else return project
    })
    setProjects(updatedProjects);
  }

  return (
    <>
      {/* Jumbotron Replacement */}
      <div className="p-5 mb-4 bg-light border rounded-3 pageHeader">
        <div className="container-fluid py-2">

          <h1>PROJECTS</h1>
        </div>
      </div>

      {/* Featured Projects Section */}
      <Card className='projectCard' id="audioviz">
        <Card.Header
        className='clickableCard'
        onClick={() => toggleProject('audioviz')}
        aria-controls="example-collapse-text"
        aria-expanded={projects[projects.findIndex(x => x.id === 'audioviz')].open}
        >Audio Visualizer</Card.Header>
        <Collapse in={projects[projects.findIndex(x => x.id === 'audioviz')].open}>
        <Card.Body>
          <Card.Img src={audioviz} className='projectHeaderImage'></Card.Img>
          <Card.Text className='projectInfo'>
          A web based audio visualizer! This site features 9 songs that users can pick from. An automatic tempo detection system will dispaly the estimated tempo of the current song, and the visualization will be affected by the song's tempo as well. Users also have the ability to select their own audio files that will last for the duration of the session.
          <br />
          <a className='projectLink' href='https://kiernanbrown.com/audioviz/' target='_blank'>Visualize Audio Here</a>
          </Card.Text>
          <Card.Img src={av1} className='projectImage'></Card.Img>
          <Card.Img src={av2} className='projectImage'></Card.Img>
        </Card.Body>
        </Collapse>
      </Card>

      <Card className='projectCard' id="onenight">
        <Card.Header
        className='clickableCard'
        onClick={() => toggleProject('onenight')}
        aria-controls="example-collapse-text"
        aria-expanded={projects[projects.findIndex(x => x.id === 'onenight')].open}
        >One Night</Card.Header>
        <Collapse in={projects[projects.findIndex(x => x.id === 'onenight')].open}>
        <Card.Body>
          <Card.Img src={onenight} className='projectHeaderImage'></Card.Img>
          <Card.Text className='projectInfo'>
          One Night is an online version of One Night Ultimate Werewolf, a social deduction game by Bezier Games. This is a game that I really enjoyed playing with friends I used to live with, and I created this since there was no direct online alternative for us to play it now that we are apart. Players can join a game by putting in a username and entering the desired number of players for the game (4-8) and then they are placed in a room with other players. Games automatically start once their required player count has been reached. Players are given roles with unique actions and must use these to gain information or influence discussions in ways that benefit their team. This fast paced social deduction game is easy to pick up and perfect for multiple rounds with the same people.
          <a className='projectLink' href='https://one-night.onrender.com' target='_blank'>Play One Night Here</a>
          </Card.Text>
        </Card.Body>
        </Collapse>
      </Card>

      <Card className='projectCard' id="prism">
        <Card.Header
        className='clickableCard'
        onClick={() => toggleProject('prism')}
        aria-controls="example-collapse-text"
        aria-expanded={projects[projects.findIndex(x => x.id === 'prism')].open}
        >Prism</Card.Header>
        <Collapse in={projects[projects.findIndex(x => x.id === 'prism')].open}>
        <Card.Body>
          <Card.Img src={prism} className='projectHeaderImage'></Card.Img>
          <Card.Text className='projectInfo'>
          Prism is a 2D Puzzle Platformer that can be played completely in the browser. Pick from 15 different stages with 3 different play styles and challenge yourself to clear the stage in the shortest amount of time possible. High scores are saved locally and persist between sessions.
          <a className='projectLink' href='https://kiernanbrown.com/prism/' target='_blank'>Play Prism Here</a>
          </Card.Text>
          <Card.Img src={p1} className='projectImage'></Card.Img>
          <Card.Img src={p2} className='projectImage'></Card.Img>
        </Card.Body>
        </Collapse>
      </Card>
      
      <Card className='projectCard' id="data">
        <Card.Header
        className='clickableCard'
        onClick={() => toggleProject('data')}
        aria-controls="example-collapse-text"
        aria-expanded={projects[projects.findIndex(x => x.id === 'data')].open}
        >Data Visualization Examples</Card.Header>
        <Collapse in={projects[projects.findIndex(x => x.id === 'data')].open}>
        <Card.Body>
          <Card.Text className='projectInfo'>
          The following projects are some examples of data visualization that I have done. One project uses a data set of the most owned steam games, and the other project uses a data set of popular twitch streams. For these projects I cleaned and formatted both data sets, created a way for users to browse that data in a more effective way, and made my own conclusions from the data that was presented.
          <a className='projectLink' href='https://kiernanbrown.com/steam-data/' target='_blank'>Steam Data Visualization</a>
          <a className='projectLink' href='https://kiernanbrown.com/twitch-data/' target='_blank'>Twitch Data Visualization</a>
          </Card.Text>
          <Card.Img src={data1} className='projectImage'></Card.Img>
          <Card.Img src={data2} className='projectImage'></Card.Img>
        </Card.Body>
        </Collapse>
      </Card>
    </>
  );
};

export default Projects;