import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import logo from '../media/kb_transparent.png';

import './Navigation.css';

const Navigation = () => {
  const getActiveKey = (loc) => {
    return loc.pathname.length > 0
      ? loc.pathname.split('/')[1]
      : loc.pathname;
  };
  const location = useLocation();

  return (
    <Navbar bg='dark' variant="dark" data-bs-theme="dark" collapseOnSelect expand='sm'>
      <Container>
        <Navbar.Brand as={Link} to='/'>
          <img
            alt='Kiernan Brown Logo'
            src={logo}
            className='d-inline-block align-top logo'
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsiveNavbar' />
        <Navbar.Collapse id='responsiveNavbar'>
          <Nav activeKey={getActiveKey(location)} className='me-auto'>
            <Nav.Item>
              <Nav.Link eventKey='projects' as={Link} to='/projects'>
                Projects
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='about' as={Link} to='/about'>
                About Me
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='skills' as={Link} to='/skills'>
                Skills
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='contact' as={Link} to='/contact'>
                Contact
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
