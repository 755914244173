import Card from 'react-bootstrap/Card';
import React from 'react';

import './About.css';

const About = () => {
  return (
    <>
      {/* Jumbotron Replacement */}
      <div className="p-5 mb-4 bg-light border rounded-3 pageHeader">
        <div className="container-fluid py-2">
          <h1>ABOUT</h1>
        </div>
      </div>

    <Card className='aboutCard'>
      <Card.Header
        >About Me</Card.Header>
        <Card.Body>
        <div className='aboutSection'>
        Hello! I'm Kiernan Brown and I'm currently searching for a full time entry level position in the web development, software development, or game development fields. I grew up in and spent my whole life in South Brunswick New Jersey. Video Games are my passion. I've been hooked on video games ever since I played Pokémon Red, the first game I can remember beating, back when I was about 5 or 6. Ever since then, I've known that I wanted to create video games and create experiences that people can enjoy. I believe that video games have a magical quality to them, and I want to be able to share that magic with the people that play the games I make.
        </div>

        <div className='aboutSection'>
        Over time this passion for gaming has transitioned into a passion for general software development and being able to create experiences that people can enjoy. I love programming and seeing the work that I put in turn into something that other people can play and interact with. More recently I've become much more interested in web development after realizing how powerful the web can be for creating interactive experiences so most of my focus in the last few years has been in this field.
        </div>
        
        <div className='aboutSection'>
        When it comes to my other interests, and when I'm not programming, I spend a good amount of my free time speedrunning different video games and streaming on Twitch. It's been a great experience meeting and interacting with other people, collaborating with them on different games, and learning from them. This hobby as a whole has helped me to be more goal driven and motivated, and I feel that speedrunning has also given me a new perspective on both playing and designing video games. Streaming has also been a great boost to my confidence and my communication skills as well. As a result of the pursuit of this hobby, I was featured in the Guinness World Records: Gamers Edition 2020 book for my Kingdom Hearts III Beginner Any% record. I've also showcased different speedruns in online marathons that raised money for charity, such as <a href='https://www.youtube.com/watch?v=B6QS0bteL0M' target='_blank'>Kingdom Hearts 3 at Velocity Online 2021</a> and <a href='https://www.youtube.com/watch?v=umgxXNif8mM' target='_blank'>Scarlet Nexus at AnimeSpeedrunsFestival2022</a>. I've also participated on commentary for many other marathons like Awesome Games Done Quick 2022 and ESA Winter 2022. My times can be seen <a href='https://www.speedrun.com/users/SwiftShadow' target='_blank'>here on my speedrun.com profile.</a>
        </div>
        </Card.Body>
      </Card>
      


    </>
  );
};

export default About;









