import React from 'react';
import {Image, Carousel, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import headshot from '../media/kiernan.jpg';
import onenight from '../media/newnight.png';
import audioviz from '../media/newaudio.png'
import './Home.css';

const Home = () => {
  return (
    <>
      {/* Jumbotron Replacement */}
      <div className="p-5 mb-4 bg-light border rounded-3 jumbotron-container">
        <div id="jumbotron" className="container-fluid">
          <Image roundedCircle src={headshot} alt="A picture of Kiernan Brown."></Image>
          <h1>Kiernan Brown</h1>
          <h3>Web Developer | Game Developer</h3>
          <h5><a href="https://github.com/KiernanBrown" target="_blank">GitHub: KiernanBrown</a></h5>
        </div>

      {/* Featured Projects Section */}
      <Card id="projectCarousel">
      <Card.Header><b>Featured Projects</b></Card.Header>
      <Carousel interval="8000">
        <Carousel.Item>
          <Link to='/projects#audioviz'>
          <Image className='d-block w-100' src={audioviz} alt="A picture of the Audio Visualizer."></Image>
          </Link>
          <Carousel.Caption className='d-none d-md-block'>
            <p className="caption">A web based audio visualizer</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Link to='/projects#onenight'>
          <Image className='d-block w-100' src={onenight} alt="A picture of One Night."></Image>
          </Link>
          <Carousel.Caption className='d-none d-md-block'>
            <p className="caption">An online multiplayer social deduction game</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      </Card>
      
      </div>
    </>
  );
};

export default Home;

