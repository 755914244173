import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

import './Skills.css';

const Skills = () => {
  return (
    <>
      {/* Jumbotron Replacement */}
      <div className="p-5 mb-4 bg-light border rounded-3 pageHeader">
        <div className="container-fluid py-2">
          <h1>SKILLS</h1>
        </div>
      </div>
      <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Web Development Experience</Accordion.Header>
          <Accordion.Body>
          <ul>
              <li>JavaScript (ES6)</li>
              <li>TypeScript</li>
              <li>React</li>
              <li>Express</li>
              <li>NodeJS</li>
              <li>Socket.io</li>
              <li>MongoDB</li>
              <li>D3.js</li>
              <li>Canvas</li>
              <li>Handlebars</li>
              <li>Bootstrap</li>
              <li>Materialize</li>
              <li>Phaser</li>
              <li>ASP.NET</li>
              <li>Electron.NET</li>
              <li>HTML</li>
              <li>CSS</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>Programming Experience</Accordion.Header>
          <Accordion.Body>
          <ul>
              <li>C#</li>
              <li>Java</li>
              <li>C++</li>
              <li>MySQL</li>
              <li>Unity</li>
              <li>Unreal Engine 4</li>
              <li>ARCore</li>
              <li>ARKit</li>
              <li>Vuforia</li>
              <li>Swift</li>
              <li>Monogame</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Software Experience</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Microsoft Visual Studio</li>
              <li>Visual Studio Code</li>
              <li>Android Studio</li>
              <li>Xcode</li>
              <li>Adobe Photoshop</li>
              <li>Adobe Premiere Pro</li>
              <li>Adobe After Effects</li>
              <li>Sony Vegas</li>
              <li>Autodesk Maya</li>
              <li>Blender</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default Skills;









